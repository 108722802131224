import Head from "next/head";
import Header from "@components/layout/header";
import Footer from "../Footer";
import DropdownPanelDesktop from "../menu/dropdownPanelDesktop.js";
import DropdownPanelMobile from "../menu/dropdownPanelMobile.js";
import LogoutButton from "../auth/logoutButton.js";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = process.env.ZENDESK_KEY;
import { useEffect, useState } from "react";
import api from "../../src/api/index.js";
import { useRouter } from "next/router";

import { Inter } from "next/font/google";

const interFont = Inter({
  weight: ["300", "400", "500", "600", "700", "800"],
  subsets: ["latin"],
});

var showPrescribingLink = null;

export default function Layout({ children, session, chat = false }) {
  const [url, setUrl] = useState("");
  const [liveEvents, setLiveEvents] = useState([]);

  const router = useRouter();

  const getLiveEvents = async () => {
    if (router.asPath !== "/home") return;

    const liveEvents = await new api.GetLiveEvents().doQuery(
      null,
      session.user.image.access_token
    );
    setLiveEvents(liveEvents);
  };

  const getPrescribingLink = async () => {
    console.log("callled prescribign -> ");
    if (!session) return;
    const prescribinglink = await new api.ShowPrescribingLink(
      session.user.image.user_id
    ).doQuery();
    showPrescribingLink = prescribinglink === null ? false : true;
  };

  useEffect(() => {
    setUrl(window.location.href);

    getLiveEvents();
  }, []);

  useEffect(() => {
    if (showPrescribingLink !== null) return;
    // getPrescribingLink()
  }, []);

  const setting = {
    webWidget: {
      setLocale: "es",
      zIndex: 19,
    },
  };

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
          name="viewport"
        />
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href="/img/favicon.png" />

        {/* <script src="https://addevent.com/libs/atc/1.6.1/atc.min.js" defer></script>
        <script src="https://embed.typeform.com/embed.js"></script> */}

        {/* <script type="text/javascript" src={`${process.env.NEXTAUTH_URL}/js/atc.min.js`} async defer></script> */}

        {/* <link rel="stylesheet" href="https://rsms.me/inter/inter.css" /> */}

        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <title>Boehringer Click</title>
        {/* {
                    VALUE_ENV === 'development' &&
                    <script
                        async
                        src={"https://script.bi-instatag.com?ref=" + encodeURIComponent(url)}
                    ></script>
                } */}
      </Head>

      <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={() => console.log("Zendesk is loaded")}
      />

      <div className={`${interFont.className}`}>
        {session && (
          <Header session={session} liveEvents={liveEvents}>
            <DropdownPanelDesktop
              key="DropdownPanelDesktop"
              showPrescribingLink={showPrescribingLink}
              session={session}
            >
              <LogoutButton session={session} chat={chat} />
            </DropdownPanelDesktop>

            <DropdownPanelMobile
              liveEvents={liveEvents}
              key="DropdownPanelMobile"
              showPrescribingLink={showPrescribingLink}
              session={session}
            >
              <LogoutButton session={session} />
            </DropdownPanelMobile>
          </Header>
        )}

        <div
          className={`bg-gray-100 font-sans antialiased overflow-x-hidden ${
            session ? "mt-16 lg:mt-16" : ""
          } `}
        >
          {children}
        </div>

        <Footer />
      </div>
    </>
  );
}
