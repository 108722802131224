import type { Dispatch, PropsWithChildren, SetStateAction } from "react";

import { Transition } from "@headlessui/react";

import { useState } from "react";

import MenuDesktopIcon from "../../public/img/menuDesktop.svg";

import GreenLogo from "../../public/img/green-logo-2.png";
import Touch from "../../public/img/Boehringer_Standalone_Touch_RGB_Accent-Green.png";

import { useRouter } from "next/router";

import LiveButton from "../liveButton";

import Link from "next/link";
import Image from "next/image";

import ModalSearch from "../modals/ModalSearch";

import { SearchClient } from "algoliasearch/lite";

import type { LiveEvent, Session } from "@src/types/index.interface";

type CProps = {
  liveEvents: LiveEvent[];
  session: Session;
  setModalSearchState: Dispatch<SetStateAction<boolean>>;
  modalSearchState: boolean;
  searchState: string;
  algoliaClient: SearchClient;
};

export const DesktopNav = (props: PropsWithChildren<CProps>) => {
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);

  const { asPath } = useRouter();

  return (
    <>
      <nav className="bg-bi-green-deep-teal-950 h-16 border-b border-bi-green-deep-teal-900 relative xl:flex space-x-2 items-center justify-between hidden px-6">
        <div className="flex space-x-6">
          <Link href="/" className="inline">
            <Image src={GreenLogo} alt="" className="h-auto w-24" />
          </Link>

          <div className="flex items-center space-x-4">
            <Link href="/">
              <span
                className={`px-3 py-2 rounded-md text-sm cursor-pointer ${
                  asPath === "/home" ? "text-white font-bold" : "text-white"
                }`}
              >
                Home
              </span>
            </Link>
            <Link href="/my-account">
              <span
                className={`px-3 py-2 rounded-md text-sm  cursor-pointer  ${
                  asPath === "/my-account"
                    ? "text-white font-bold"
                    : "text-white"
                }`}
              >
                My account
              </span>
            </Link>

            {!!props.liveEvents.length && asPath === "/home" && (
              <LiveButton liveEvents={props.liveEvents} />
            )}
          </div>
        </div>

        {/* 2 */}
        <div className="hidden lg:block max-w-lg w-full lg:max-w-xs z-20">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="">
            <ModalSearch
              algoliaClient={props.algoliaClient}
              searchState={props.searchState}
              closeModal={() => props.setModalSearchState(false)}
              modalSearchState={props.modalSearchState}
              setModalSearchState={props.setModalSearchState}
            />
          </div>
        </div>

        <div className="flex">
          <Link href="/" className="flex items-center">
            <Image src={Touch} alt="" className="h-auto w-10" />
            <span className="text-bi-green-logo text-2xl mr-4">Click</span>
          </Link>

          <button
            className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            id="menu"
            aria-haspopup="true"
            onClick={() => setIsDesktopMenuOpen(!isDesktopMenuOpen)}
          >
            <span className="sr-only">Open menu</span>
            <MenuDesktopIcon />
          </button>
        </div>
      </nav>

      <Transition
        show={isDesktopMenuOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {props.children}
      </Transition>
    </>
  );
};

export default DesktopNav;
