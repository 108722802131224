import { useState, useEffect } from "react";
import Link from "next/link";
//import LiveButton from '../../components/liveButton.js'
//import { useRouter } from 'next/router'

export default function DropdownPanelMobile({
  children,
  session,
  showPrescribingLink,
}) {
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.pathname);
  });

  //const router = useRouter()

  return (
    <div className="lg:hidden bg-bi-green-deep-teal-950 absolute z-60 w-full">
      <div className="px-2 pt-2 pb-3 space-y-1">
        <Link
          href="/"
          className={`${
            path === "/"
              ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
              : "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          }`}
        >
          Home
        </Link>

        <Link
          href="/my-account"
          className={`${
            path === "/my-account"
              ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
              : "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          }`}
        >
          My Account
        </Link>

        {(showPrescribingLink || Number(session.user.image.role) >= 5) && (
          <Link
            href="/ipp"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            role="menuitem"
          >
            Prescribing Information
          </Link>
        )}
      </div>
      <div className="pt-4 pb-3 border-t border-gray-700">
        <div className="mt-3 px-2 space-y-1">
          <Link
            href="/edit-profile"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          >
            Edit Profile
          </Link>

          <Link
            href="/medical-information-request"
            className="bblock px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            role="menuitem"
          >
            Medical Information Request
          </Link>
          {children}
        </div>
      </div>
    </div>
  );
}
