import { useState } from "react";
import LiveIcon from "../public/img/live.svg";
import { useRouter } from "next/router";
import ModalLiveEvents from "./modals/ModalLiveEvents";

export default function LiveButton({ liveEvents }) {
  const router = useRouter();
  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const goToEvent = () => {
    console.log("live event -> ", liveEvents[0].slug);

    if (liveEvents.length === 1) router.push(`/live/${liveEvents[0].slug}`);

    if (liveEvents.length > 1) setModalState(true);
  };

  return (
    <>
      <button
        onClick={goToEvent}
        className="fixed lg:w-40 top-2 z-10 right-14 ml-16 text-gray-50 rounded-full text-xs whitespace-nowrap bg-bi-green-malachite-800 px-3 h-11 m-0 flex flex-row items-center justify-center sm:text-sm sm:px-5 lg:left-84"
      >
        <LiveIcon className="w-5 h-7 mr-1 lg:mr-2 lg:w-7 lg:h-7 fill-current text-gray-50" />
        Live now
      </button>

      {modalState && (
        <ModalLiveEvents liveEvents={liveEvents} toggleModal={toggleModal} />
      )}
    </>
  );
}
