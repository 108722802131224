import CloseIcon from "../../public/img/close.svg";
import Link from "next/link";

export default function ModalLiveEvents({ liveEvents, toggleModal }) {
  return (
    <div className="fixed z-10 inset-0">
      <div className="flex items-center justify-center min-h-screen min-w-screen lg:pb-20">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-95"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="transition-all w-full h-screen flex justify-center items-center"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="rounded-lg w-11/12 sm:w-3/6 z-10">
            <div className="w-full bg-bi-green-malachite-800 rounded-t-lg px-4 py-2 flex justify-between items-center">
              <p className="text-base text-white font-semibold">Live events:</p>
              <div style={{ fill: "#FFFFFF" }}>
                <button onClick={toggleModal}>
                  <CloseIcon
                    width="40px"
                    height="40px"
                    className="text-white cursor-pointer"
                  />
                </button>
              </div>
            </div>

            <div className="w-full p-5 bg-white">
              <div className="lg:grid lg:grid-cols-12 lg:gap-4 h-full">
                {liveEvents.map(({ id, slug, title, upcoming_thumb_url }) => {
                  return (
                    <div key={id} className="col-span-6 h-full mb-14 lg:mb-0">
                      <img
                        src={`${process.env.IMAGES_URL}/${upcoming_thumb_url}`}
                        className="w-40 h-auto lg:w-full"
                        alt=""
                      />
                      <h2 className="mb-8 text-gray-900 text-sm">{title}</h2>
                      <Link
                        href={`/live/${slug}`}
                        className="text-gray-50 px-8 w-full py-3 rounded-full text-sm whitespace-nowrap bg-bi-green-malachite-800"
                      >
                        Live now
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
